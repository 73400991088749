<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <button @click="click">点击连接钱包并查找BSC链的USDC的一些信息</button>
    <p>USDN-Name:{{nameRef}}</p>
    <p>USDN-Decimals:{{decimalsRef}}</p>
    <p>USDN-Balance:{{balanceRef}}</p>
  </div>
</template>

<script setup>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import EthWallet from "@/assets/js/EthUtils.js";
import {ref} from "vue";
const wallet = new EthWallet();
const USDCAddress = '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2';
const nameRef = ref('');
const decimalsRef = ref('');
const balanceRef = ref('');
const click = async () => {
  await wallet.initBSC();
  wallet.updateTokenContract(USDCAddress);
  nameRef.value = await wallet.contractFn('name');
  decimalsRef.value = await wallet.contractFn('decimals');
  balanceRef.value = await wallet.contractFn('balanceOf',wallet.signer.address);
}
</script>
